import React from 'react';
import { Fade } from 'react-awesome-reveal';
import Section from 'gatsby-theme-mate/src/components/Section';
import { CardContainer } from 'gatsby-theme-mate/src/components/Card';
import Triangle from 'gatsby-theme-mate/src/components/Triangle';
import { useMediumQuery } from 'gatsby-theme-mate/src/queries/useMediumQuery';
import { Post } from 'gatsby-theme-mate/src/components/Post';
import { SECTION } from 'gatsby-theme-mate/src/utils/constants';

const Writing = () => {
  const { posts } = useMediumQuery();

  return (
    <Section.Container id={SECTION.writing} Background={Background}>
      {/* <Section.Header name={SECTION.writing} icon="✍️" label="writing" /> */}
      <Section.Header name={SECTION.writing} label="writing" />
      <CardContainer minWidth="350px">
        <Fade direction="down" triggerOnce cascade damping={0.5}>
          {posts.map((p) => (
            <Post {...p} key={p.url} />
          ))}
        </Fade>
      </CardContainer>
    </Section.Container>
  );
};

const Background = () => (
    <>
      <Triangle
        color="secondary"
        height={['80vh', '80vh']}
        width={['100vw', '100vw']}
        position="top-right"
      />
  
      <Triangle
        color="background"
        height={['50vh', '20vh']}
        width={['50vw', '50vw']}
        position="top-right"
      />
  
      <Triangle
        color="primary"
        height={['25vh', '40vh']}
        width={['75vw', '60vw']}
        position="bottom-right"
      />
  
      <Triangle
        color="muted"
        height={['25vh', '20vh']}
        width={['100vw', '100vw']}
        position="bottom-right"
      />
    </>
  );

// const Background = () => (
//   <>
//     <Triangle
//       color="muted"
//       height={['15vh', '10vh']}
//       width={['100vw', '100vw']}
//       position="top-left"
//     />

//     <Triangle
//       color="secondary"
//       height={['50vh', '40vh']}
//       width={['70vw', '40vw']}
//       position="bottom-left"
//     />

//     <Triangle
//       color="primary"
//       height={['40vh', '15vh']}
//       width={['100vw', '100vw']}
//       position="bottom-right"
//     />
//   </>
// );

export default Writing;
